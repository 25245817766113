// Copyright 2024 pierreneter. All rights reserved.

import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../Common/ScrollToTop";

const RootLayout: React.FC = () => {
  return (
    <>
      <Outlet />
      <ScrollToTop />
    </>
  );
};

export default RootLayout;
