// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import RootLayout from "../Layout/RootLayout";
import { LazyHome, LazyNotFound } from "./elements";

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<LazyHome />} />
            <Route path="/chains" element={<LazyHome />} />
            <Route path="/chain/:chain" element={<LazyHome />} />
            <Route path="*" element={<LazyNotFound />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
