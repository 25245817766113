// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import React, { lazy, Suspense } from "react";

const create =
  (Inner: React.LazyExoticComponent<React.FC>, Loading?: React.ReactNode): React.FC =>
  () => {
    if (Loading) return <Suspense fallback={Loading}>{<Inner />}</Suspense>;
    return <Suspense fallback={<div></div>}>{<Inner />}</Suspense>;
  };

export const LazyHome = create(lazy(() => import("../../screens/Home/Home")));
export const LazyNotFound = create(lazy(() => import("../../screens/NotFound")));
export const LazyHeader = create(lazy(() => import("../../components/Header/Header")));
export const LazyFooter = create(lazy(() => import("../../components/Footer/Footer")));
export const LazyHero = create(lazy(() => import("../../components/Home/Hero")));
export const LazyMainnet = create(lazy(() => import("../../components/Home/Mainnet")));
export const LazyTestnet = create(lazy(() => import("../../components/Home/Testnet")));
export const LazyDApps = create(lazy(() => import("../../components/Home/DApps")));
export const LazyPartners = create(lazy(() => import("../../components/Home/Partners")));
