// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import React from "react";
import PopupProvider from "../Popup/PopupProvider";
import AppRouter from "../Router/AppRouter";
import AppToast from "../Toast/AppToast";

const BasicApp: React.FC = () => {
  return (
    <PopupProvider>
      <AppRouter />
      <AppToast />
    </PopupProvider>
  );
};

export default BasicApp;
