// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import React from "react";
import { Outlet } from "react-router-dom";
import { LazyFooter, LazyHeader } from "../Router/elements";

const MainLayout: React.FC = () => {
  return (
    <main className="flex flex-col min-h-[100vh]">
      <LazyHeader />
      <div className="flex-1">
        <Outlet />
      </div>
      <LazyFooter />
    </main>
  );
};

export default MainLayout;
